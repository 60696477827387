// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';

import BreadCrumb from 'components/breadCrumb';
import AddressTimes from 'components/units/unitPages/unit/addressTimes';
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';
import Components from 'components/CmsComponents/';

// Methods
import { removeItemFromSpecialtyList } from 'utils/removeItemFromSpecialtyList';

//Images
import MapImage from 'images/mapsImages/especialidades-medicas-brasilia.png';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = (page, servicesListMarkdown = '') => (
  <FeaturedMarketing
    WithoutItemBg
    singleText={getMarkdown(page, 'cklhy3tewoxfp0a7793qjx7ac')}
    servicesList={servicesListMarkdown || ''}
    asset={getMarkdown(page, 'cklhy3tewoxfp0a7793qjx7ac', false, true)}
  />
);

const RenderPage = (page, isDesktop, location, specialties) => {
  const filteredSpecialties = removeItemFromSpecialtyList(specialties);
  const servicesList =
    filteredSpecialties.length > 0 &&
    filteredSpecialties
      ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() && 1) || -1)
      .map(specialty => {
        if (specialty.customUrl) {
          return `- ![arrow.svg](${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/mysEtnOWQraizwl0zuY8)[${specialty.name}](${specialty.customUrl})`;
        } else {
          return `- ![arrow.svg](${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/mysEtnOWQraizwl0zuY8)[${specialty.name}](/especialidades-medicas/brasilia/${specialty.slug}/)`;
        }
      });

  const servicesListFormattedAsMarkdown =
    servicesList.length && servicesList.join('\n');

  const components = page[0]?.components ?? [];

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <BreadCrumb
          details
          markdown={getMarkdown(page, 'cklhxlq6gow770a74h8xuymf5', true)}
        />
        <DetailsTitle
          titleWidth="20rem"
          titleWidthMobile="18rem"
          markdown={getMarkdown(page, 'cklhxw5y8owv50a77vgsr7j2n', true)}
        />
      </Part>
      {!isDesktop &&
        renderFeaturedMarketing(page, servicesListFormattedAsMarkdown)}
      <Container>
        <Part gridColumn={'1 / span 6'}>
          {isDesktop &&
            renderFeaturedMarketing(page, servicesListFormattedAsMarkdown)}
          {/* About */}
          <TextSection
            isAbout
            markdown={getMarkdown(page, 'cklhysqsgp3nz0a74q3qnt9te', true)}
            gridArea="ABT"
            isAboutMobile
            isShowing
          />
          {/* Structure */}
          <TextSection
            markdown={getMarkdown(page, 'cklhyw70op41o0a745ej13jvq', true)}
            gridArea="STRUC"
            isAboutMobile
            isShowing
          />
          {/* Others Units Mobile*/}
          <TextSection
            markdown={getMarkdown(page, 'cklhyyhkwp14i0a20qjx7azfs', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
          />
        </Part>
        <Part gridColumn={'8 / span 5'}>
          <AddressTimes
            isShowingAttention
            markdown={getMarkdown(page, 'cklhykk1cozo10a77raa463yo', true)}
            fragments={getMarkdown(page, 'cklhykk1cozo10a77raa463yo')}
            location={{ lat: -15.835382676054941, lng: -47.91346977161368 }}
            zoom={16}
            isDetails
            isWhatsapp
            mapPreview={MapImage}
            isStatic
            isDesktop={isDesktop}
          />
          {/* Schedule */}
          <TextSection
            markdown={getMarkdown(page, 'cklhymlc8p1mi0a74ktdwr1xs', true)}
            gridArea="SCH"
            isShort
            isAboutMobile
            isShowing
            withoutBullets
          />
          {/* Parking */}
          <TextSection
            markdown={getMarkdown(page, 'cklhyp9sgp1lm0a71dk90x51m', true)}
            gridArea="PAR"
            isShort
            isAboutMobile
            isShowing
          />
          {/* Others Units */}
          <TextSection
            markdown={getMarkdown(page, 'cklhyyhkwp14i0a20qjx7azfs', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
            isOthersUnitsMobile
            isShowing
          />
          {isDesktop && (
            <SocialShare location={location} title="Especialidades Brasilia" />
          )}
        </Part>
      </Container>
      {!isDesktop && (
        <SocialShare location={location} title="Especialidades Brasilia" />
      )}
      <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </Part>
    </GridContainer>
  );
};

const EspecialidadesBrasilia = ({ page, location, specialties }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cklhy3tewoxfp0a7793qjx7ac', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop, location, specialties)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cklhxp4v4owdo0a7432ttkune" }) {
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
            specialties(where: { unity_contains_some: bsb }) {
              name
              slug
              customUrl
            }
          }
        }
      `}
      render={response => {
        return (
          <EspecialidadesBrasilia
            page={response.gcms.site.pages}
            location={location}
            specialties={response.gcms.specialties}
          />
        );
      }}
    />
  );
};
